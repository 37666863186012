import { useState, useEffect } from "react";

import './Mentoring.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import ModelPages_Home from "components/ModelPages/Home";
import ModelPages_VideoPresentation from "components/ModelPages/VideoPresentation";
import ModelPages_StudentWorks from "components/ModelPages/StudentWorks";
import ModelPages_ExplanationContents from "components/ModelPages/ExplanationContents";
import ModelPages_PersonalizedService from "components/ModelPages/PersonalizedService";
import ModelPages_TeacherProfile from "components/ModelPages/TeacherProfile";
import ModelPages_ModulesWorkload from "components/ModelPages/ModulesWorkload";
import ModelPages_Depositions from "components/ModelPages/Depositions";

export default function Page_Mentoring(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('mentoring'));

    useEffect(()=>{
        RegisterDataPage('mentoring', setDataPage);
    }, []);

    function TypeDataPage(value, index){
        switch(value){
            case "Home":
                return <ModelPages_Home type="mentoring" index={ index } />;

            case "Apresentação com vídeo":
                return <ModelPages_VideoPresentation type="mentoring" index={ index } />;
            
            case "Trabalhos de alunos":
                return <ModelPages_StudentWorks type="mentoring" index={ index } />;
            
            case "Explicação dos conteúdos":
                return <ModelPages_ExplanationContents type="mentoring" index={ index } />;
            
            case "Atendimento personalizado":
                return <ModelPages_PersonalizedService type="mentoring" index={ index } />;
            
            case "Perfil do professor":
                return <ModelPages_TeacherProfile type="mentoring" index={ index } />;
            
            case "Módulos e carga horária":
                return <ModelPages_ModulesWorkload type="mentoring" index={ index } />;

            case "Depoimentos":
                return <ModelPages_Depositions type="mentoring" index={ index } />;

            // case "paymenttypes":
            //     return <PaymentTypes type="mentoring" index={ index } />;
        }
    }
    
    return(
        <div className="Page_Mentoring">
            {
                Object.keys(dataPage).map((key, index)=>{
                    return(
                        <div className="show_data_page" key={ index }>
                            {
                                TypeDataPage(dataPage[key].type, index)
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}