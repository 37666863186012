import { useState, useEffect } from "react";

import './ExplanationContents.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

import { SvgPdf } from "components/SvgFile";

export default function ModelPages_ExplanationContents(props){

    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);

    return(
        <div className="ModelPages_ExplanationContents" style={ { background: showData.bg_page } }>
            <div className="container show_data">
                <div className="div_text">
                    {
                        showData.title_1 !='' ? 
                        <div className="div_title">
                            <div className="title">{ showData.title_1 }</div>
                        </div> : null
                    }
                    {
                        showData.text_1 !='' ? <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_1.replaceAll('&#34;', '"') } } /> : null
                    }
                    {
                        showData.title_2 !='' ? <div className="subtitle">{ showData.title_2 }</div> : null
                    }                    
                    {
                        showData.text_2 !='' ? <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_2.replaceAll('&#34;', '"') } } /> : null
                    }
                    {
                        showData.title_3 !='' ? <div className="subtitle">{ showData.title_3 }</div> : null
                    }     
                    {
                        showData.text_3 !='' ? <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_3.replaceAll('&#34;', '"') } } /> : null
                    }
                    {
                        showData.name_1 !='' || showData.name_2 !='' ?
                        <div className="file">
                            <a href={ showData.file } className="show_file" target="_blank">
                                <div className="show_icons">
                                    <SvgPdf color="#FF5200" className="pdf" />
                                </div>
                                <div className="text_pdf">
                                    { 
                                        showData.name_1 !='' ? <div className="">{ showData.name_1 }</div> : null
                                    }
                                    { 
                                        showData.name_1 !='' ? <div className="">{ showData.name_2 }</div> : null
                                    }                              
                                </div>
                            </a>
                        </div> : null
                    }
                </div>
                <div className="list_img">
                        <img alt="01" src={ showData.img } className="img" />
                </div>
            </div>
        </div>
    )
}