import { useState, useEffect } from "react";

import './Dashboard.css';

import Cookies from 'universal-cookie';

export default function Page_Dashboard(){
    return(
        <div className="">
            Teste 123
        </div>
    )
}