const ModalState = {
    NewData         : false,
    Confirmation    : false,
    Notification    : false,
    ShowFile        : false,
    RecoverPassword : false,
    CookieChecked   : false
};

const ModalData = {
    NewData         : {},
    Confirmation    : {},
    Notification    : {},
    ShowFile        : {},
    RecoverPassword : {},
    CookieChecked   : {}
}

const NotifyModalObserver = {
    NewData         : [],
    Confirmation    : [],
    Notification    : [],
    ShowFile        : [],
    RecoverPassword : [],
    CookieChecked   : []
}

const NotifyModalData = {
    NewData         : [],
    Confirmation    : [],
    Notification    : [],
    ShowFile        : [],
    RecoverPassword : [],
    CookieChecked   : []
}

export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}

export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}

export function GetModalState(key, value){
    return ModalData[key][value];
}

export function GetModalData(key){
    return ModalData[key];
}

export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}

export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}