import { useState, useEffect, useRef } from "react";

import './Home.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_Home(props){

    const textWidth    = useRef();
    
    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    const [ height, setHeight ]     = useState(0);
    const [ checked, setChecked ]   = useState(0)
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);

    return(
        <div className="ModelPages_Home">
            <div className="show_text">
                {
                    showData.title_1 ? <div className="title">{ showData.title_1 }</div> : null
                }                
                <div className="div_highlight">
                    {
                        showData.title_2 ? <div className="text">{ showData.title_2 }</div> : null
                    }
                    {
                        showData.title_3 ? <div className="highlight">{ showData.title_3 }</div> : null
                    }                   
                    <div className="list_text">
                        {
                            showData.title_4 ? <div className="text">{ showData.title_4 }</div> : null
                        }  
                        {
                            showData.title_5 ? <div className="text">{ showData.title_5 }</div> : null
                        }  
                        {
                            showData.title_6 ? <div className="text">{ showData.title_6 }</div> : null
                        }  
                    </div>
                </div>
                <div className="button">
                    <div className="button_text">{ showData.title_7 }</div>
                    <a href="/#enrollment">
                        <div className="button_text" onClick={ ()=>{ props.CkickPage('enrollment') } }>{ showData.title_8 }</div>
                    </a>
                </div>
            </div>
            <div className="background_image">
                <img alt="img" src={ showData.bg_page } className="img_home" />
            </div>
        </div>
    )
}