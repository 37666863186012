import { useState, useEffect } from "react";

import './HowWorks.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgTriagle } from "components/SvgFile";

export default function Page_HowWorks(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('howworks'));
    const [ showData, setShowData ] = useState(dataPage.doubts);
    
    useEffect(()=>{
        RegisterDataPage('howworks', setDataPage);
    }, []);

    function AltStateDoubts(index, state){
        const newState = [...showData];
        newState.map((key, index)=>{
            key.state = false;
        })
        newState[index].state = state;
        setShowData(newState);
    }
    
    return(
        <div className="Page_HowWorks">
            <div className="container show_data">
                <div className="title">{ dataPage.title_1 }</div>
                <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text } } />
                <div className="line_color" />
                <div className="title">{ dataPage.title_2 }</div>
                <div className="list_doubts">
                    {
                        showData.map((key, index)=>{
                            return(
                                <div key={ index }>
                                    <div className="doubts" onClick={ ()=>{ AltStateDoubts(index, !key.state) }}>
                                        <div className="triagle">
                                            {
                                                key.state == true ?
                                                <SvgTriagle color="#FFFFFF" className="icons_active" /> :
                                                <SvgTriagle color="#FFFFFF" className="icons_" />
                                            }
                                        </div>
                                        <div className="">{ key.question }</div>
                                    </div>
                                    <div className={ key.state == true == true ? "response_ doubts_active" : "response_" } dangerouslySetInnerHTML={ { __html: key.response.replaceAll('&#34;', '"') } } />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}