import { useState, useEffect } from "react";

import './Home.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import ModelPages_Home from "components/ModelPages/Home";
import ModelPages_VideoPresentation from "components/ModelPages/VideoPresentation";
import ModelPages_StudentWorks from "components/ModelPages/StudentWorks";
import ModelPages_ExplanationContents from "components/ModelPages/ExplanationContents";
import ModelPages_PersonalizedService from "components/ModelPages/PersonalizedService";
import ModelPages_TeacherProfile from "components/ModelPages/TeacherProfile";
import ModelPages_ModulesWorkload from "components/ModelPages/ModulesWorkload";
import ModelPages_Depositions from "components/ModelPages/Depositions";
import Page_Payment from "../Payment";

export default function Page_Home(props){

    const [ dataPage, setDataPage ]       = useState(GetDataPage('home'));
    const [ payment, setPayment ]         = useState(GetDataPage('paymenttypes'));
    const [ showPayment, setShowPayment ] = useState(CheckedPayment('home'));

    useEffect(()=>{
        RegisterDataPage('home', setDataPage);
        RegisterDataPage('paymenttypes', setPayment);
    }, []);

    function CheckedPayment(type){
        const newData = payment.page.find(item => item == type);
        // if(newData){
        //     return <Page_Payment CkickPage={ props.CkickPage } />;
        // }
        return '';
    }

    function TypeDataPage(value, index){
        switch(value){
            case "Home":
                return <ModelPages_Home type="home" index={ index } CkickPage={ props.CkickPage } />;

            case "Apresentação com vídeo":
                return <ModelPages_VideoPresentation type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Trabalhos de alunos":
                return <ModelPages_StudentWorks type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Explicação dos conteúdos":
                return <ModelPages_ExplanationContents type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Atendimento personalizado":
                return <ModelPages_PersonalizedService type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Perfil do professor":
                return <ModelPages_TeacherProfile type="home" index={ index } CkickPage={ props.CkickPage } />;
            
            case "Módulos e carga horária":
                return <ModelPages_ModulesWorkload type="home" index={ index } CkickPage={ props.CkickPage } />;

            case "Depoimentos":
                return <ModelPages_Depositions type="home" index={ index } CkickPage={ props.CkickPage } />;
        }
    }
    
    return(
        <div className="Page_Home">
            {
                Object.keys(dataPage).map((key, index)=>{
                    return(
                        <div className="show_data_page" key={ index }>
                            {
                                TypeDataPage(dataPage[key].type, index)
                            }
                        </div>
                    )
                })
            }
            {
                showPayment
            }
        </div>
    )
}