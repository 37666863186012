
import Cookies from 'universal-cookie';
import { cookiesRegister, cookie_site, cookie_id, cookie_checked } from 'dataFixed';

const cookies = new Cookies(); 

let ListPag = {
    "page"          : 'index',
    "currentPage"   : 'index',
    "currentPageId" : '',
    "CookieChecked" : ''
};

let NotifyListPag = {
    "page"          : [],
    "currentPage"   : [],
    "currentPageId" : [],
    "CookieChecked" : []
}

export function SetListPag(key, value){    
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    let minutesValid = new Date(new Date().getTime() + 60 * 60 * 3600);
    if(key == 'currentPage'){
        cookies.set(cookie_site, value, { path: '/', expires: minutesValid }, cookiesRegister);
    }

    if(key == 'currentPageId'){
        if(value == 'remuve'){
            cookies.remove(cookie_id, '', '/', cookiesRegister);
        }else {
            cookies.set(cookie_id, value, { path: '/', expires: minutesValid }, cookiesRegister);
        }
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = []; 
    }
    NotifyListPag[key].push(value);
}