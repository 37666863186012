import Axios from 'axios';

import { connectionPage } from 'dataFixed';

import { SetUserData } from 'interface/Users';

export function Reg_AttendedClasses(id, value, CallbackSuccess, CallbackError){ 

    // const cookies = new Cookies();    
    // let inf_cookies = cookies.get(registerProjectCookie_dash).split(';');  

    // const data = new FormData();
    // data.append('list_inf', 'alt_data');
    
    // data.append('email', inf_cookies[0]);
    // data.append('password', inf_cookies[1]);

    // Axios({
    //     url     : connectionPage + '/php/register.php',
    //     mode    : 'no-cors',
    //     method  : 'POST',
    //     data    : data
    // })   
    // .then(response => {

    //     let data_save = response.data.email + ';' + inf_cookies[1];
    //     cookies.set(registerProjectCookie, 'login', '/', cookiesRegister);
    //     cookies.set(registerProjectCookie_dash, data_save, '/', cookiesRegister);

    //     SetUserData('name', response.data.name);
    //     SetUserData('login', response.data.login);
    //     SetUserData('phone', response.data.phone);
    //     SetUserData('email', response.data.email);

    // }).catch((error)=>{
    //     CallbackError();
    // })
}

export function Reg_User(name, email, whatsapp, login, pass_1, termsUse, privacyPolicy, typePayment, CallbackSuccess, CallbackError){ 
    const data = new FormData();
    data.append('list_inf', 'register_user');
    
    data.append('name', name);
    data.append('email', email);
    data.append('whatsapp', whatsapp);
    data.append('login', login);
    data.append('pass_1', pass_1);
    data.append('termsUse', termsUse);
    data.append('privacyPolicy', privacyPolicy);
    data.append('typePayment', typePayment);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_RecoverEmail(email, CallbackSuccess, CallbackError){ 
    const data = new FormData();
    data.append('list_inf', 'recover_email');
    data.append('email', email);

    Axios({
        url     : connectionPage + '/php/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        CallbackSuccess();
    }).catch((error)=>{
        CallbackError();
    })
}