import { useState, useEffect } from "react";

import './VideoPresentation.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_VideoPresentation(props){

    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);

    return(
        <div className="ModelPages_VideoPresentation" style={ { background: showData.bg_page } }>
            <div className="container show_data">
                <div className="show_video">
                    <iframe className="div_video" src={ showData.typeVideo == "Vimeo" ? "https://player.vimeo.com/video/" + showData.video : "https://www.youtube.com/embed/" + showData.video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
                </div>
                <div className="div_text">
                    <div className="div_title">
                        {
                            showData.title_1 ? <div className="subtitle">{ showData.title_1 }</div> : null
                        }
                        {
                            showData.title_2 ? <div className="title">{ showData.title_2 }</div> : null
                        }
                    </div>
                    {
                        showData.text_1 ? <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_1.replaceAll('&#34;', '"') } } /> : null
                    }                    
                    {
                        showData.title_3 ? <div className="subtitle">{ showData.title_3 }</div> : null
                    }
                    {
                        showData.text_2 ? <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_2.replaceAll('&#34;', '"') } } /> : null
                    }        
                    <div className="button">
                        <div className="button_text" onClick={ ()=>{  } }>Inscreva-se</div>
                    </div>
                </div>
            </div>
        </div>
    )
}