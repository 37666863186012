import { useState, useEffect } from "react";

import './StudentWorks.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_StudentWorks(props){

    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);

    return(
        <div className="ModelPages_StudentWorks">
            <div className="container show_data">
                <div className="show_video">
                    {
                        showData.title ? <div className="title">{ showData.title }</div> : null
                    }                    
                    <div className="show_video">                        
                        <iframe className="div_video" src={ showData.typeVideo == "Vimeo" ? "https://player.vimeo.com/video/" + showData.video : "https://www.youtube.com/embed/" + showData.video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
                    </div>
                </div>
            </div>
            <div className="background_image">
                <img alt="img" src={ showData.bg_page } className="img_home" />
            </div>
        </div>
    )
}