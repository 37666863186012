import { useState, useEffect, useRef } from "react";

import './Depositions.css';

import { motion } from "framer-motion";

import { GetDataPageID, RegisterDataPage } from "interface/Data";
import { SvgSetaRight } from "components/SvgFile";

export default function ModelPages_Depositions(props){

    const textHeight = useRef();
    
    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    const [ height, setHeight ]     = useState(0);
    const [ checked, setChecked ]   = useState(0);
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setShowData(GetDataPageID(props.type, props.index));
    }, [showData]);
    
    useEffect(()=>{
        setInterval(() => {
            setChecked(1);
            if(textHeight?.current){
                setHeight(textHeight?.current.clientHeight + 80);
                window.onresize = function(){
                    setChecked(1);
                    setHeight(textHeight?.current.clientHeight + 80);
                };
            }
        }, 100);  
    }, [checked]); 

    function AltPosition(type){
        var stream = document.querySelector('.div_carousel');
        var items = document.querySelectorAll('.gallery__item');

        if(type == 'prev'){
            var prev = document.querySelector('.gallery__prev');
            prev.addEventListener('click', function () {
                stream.insertBefore(items[items.length - 1], items[0]);
                items = document.querySelectorAll('.gallery__item');
            });
        }

        if(type == 'next'){
            var next = document.querySelector('.gallery__next');
            next.addEventListener('click', function () {
                stream.appendChild(items[0]);
                items = document.querySelectorAll('.gallery__item');
            });            
        }
    }

    return(
        <div className="ModelPages_Depositions" style={ { background: showData.bg_page } }>
            <div className="container show_depositions">
                <div className="title">Depoimentos</div>
                <div className="show_img" style={ { height: height + 'px' } }>

                    <div className="gallery__prev" onClick={ ()=>{ AltPosition('prev') } }>
                        <div className="icons_carousel_prev">
                            <SvgSetaRight color="#ffffff" className="icons_galerry icons_galerry_next" />
                        </div>
                    </div>
                    <div className="gallery__next" onClick={ ()=>{ AltPosition('next') } }>
                        <div className="icons_carousel_next">
                            <SvgSetaRight color="#ffffff" className="icons_galerry" />
                        </div>
                    </div>
                    <div className="div_carousel">
                        {
                            Object.keys(showData.data_profile).map((key, index)=>{ 
                                return (    
                                    <div className="gallery__item" key={ index }>
                                        <div className="show_gallery_data" ref={ textHeight }>
                                            <div className="img">
                                                <img alt="profile" src={ showData.data_profile[key].img } className="img_profile" />
                                            </div>
                                            <div className="name">{ showData.data_profile[key].title }</div>
                                            <div className="biography" dangerouslySetInnerHTML={ { __html: showData.data_profile[key].text.replaceAll('&#34;', '"') } } />
                                            <div className="area">
                                                { showData.data_profile[key].area }
                                                <div className="date">{ showData.data_profile[key].data }</div>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            })
                        } 
                    </div>
                </div>
            </div>
        </div>
    )
}