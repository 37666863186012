import { useState, useEffect, useRef } from "react";

import Cookies from 'universal-cookie';

import { cookiesRegister, cookie_checked, cookie_email, cookie_passw, cookie_site } from "dataFixed";

import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SetModalState } from "interface/PopUp";

import FixedTopic from "FixedTopic";

import { animateScroll } from 'react-scroll';

import PopUp_CookieChecked from "components/PopUp/CookieChecked";
import FixedFooter from "FixedFooter";
import Page_Home from "components/Pages/Home";
import Page_Mentoring from "components/Pages/Mentoring";
import Page_HowWorks from "components/Pages/HowWorks";
import Page_Courses from "components/Pages/Courses";
import Page_Rolo from "components/Pages/Rolo";
import Page_Login from "components/Pages/Login";

import { Access } from "services/AccessLogin";
import Page_Enrollment from "components/Pages/Enrollment";

export default function App() {

    const cookies   = new Cookies();
    const returnTop = useRef();

    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage')); 

    const nameUrl = window.location.href.split("#");

    useEffect(()=>{     
        RegisterListPag('currentPage', setCurrentPage);
        ShowNewPage();
    }, []);

    useEffect(()=>{
        setCurrentPage(GetListPag('currentPage'));
        ShowNewPage();
    }, [currentPage]);

    useEffect(()=>{
        setCurrentPage(GetListPag('currentPage'));
        ShowNewPage();
    }, [nameUrl]);

    function ShowNewPage(){
        if(currentPage == nameUrl[1]){
            CurrentPage(currentPage);
            SetListPag('currentPage', currentPage);
        }else {
            switch(nameUrl[1]){
                case "index": case "howworks": case "mentoring": case "courses": case "rolo": case "login": case "enrollment":
                        CurrentPage(nameUrl[1]);
                        SetListPag('currentPage', nameUrl[1]);
                    break;
                default:
                        CurrentPage('index');
                        SetListPag('currentPage', 'index');
                    break
            }
        }
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
        CurrentPage(value); 
    }

    function CurrentPage(namePage){
        switch(namePage){
            case "index":  
                return <Page_Home CkickPage={ CkickPage } />;

            case "howworks": 
                return <Page_HowWorks CkickPage={ CkickPage } />;

            case "mentoring":  
                return <Page_Mentoring CkickPage={ CkickPage } />;

            case "courses":  
                return <Page_Courses CkickPage={ CkickPage } />;

            case "rolo":   
                return <Page_Rolo CkickPage={ CkickPage } />;

            case "login":  
                return <Page_Login CkickPage={ CkickPage } />;
            
            case "enrollment":
                return <Page_Enrollment CkickPage={ CkickPage } />;

            default:
                return <Page_Home CkickPage={ CkickPage } />;
        }
    }

    return (
        <div>
            <FixedTopic CkickPage={ CkickPage } />
            {
                currentPage == 'login' ? null : <div className="space_topic" />
            }   
            {
                CurrentPage(nameUrl[1])
            }   
            <FixedFooter CkickPage={ CkickPage } animateScroll={ animateScroll } />
        </div>
    );
}