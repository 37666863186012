import React, { useState, useEffect } from "react";

import './RecoverPassword.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgClose } from "components/SvgFile";

import { Reg_RecoverEmail } from "services/Register";

export default function PopUp_RecoverPassword(){
    
    const [ loading, setLoading ]     = useState(false);
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ email, setEmail ]         = useState('');
    const [ msgReturn, setMsgReturn ] = useState('');

    useEffect(()=>{
        RegisterModalObserver('RecoverPassword', setShowPopUp);
    }, []);

    function ClosePopUp(){        
        SetModalState('RecoverPassword', false);
    }

    function Register(event){
        event.preventDefault();
        setLoading(true);
        Reg_RecoverEmail(email, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        setMsgReturn('Foi enviado um link para seu e-mail com instruções para criar uma nova senha.');
    }

    function CallbackError(){
        setLoading(false);
        setMsgReturn('E-mail não encontrado!');
    }

    return (
        (
            showPopUp ? 
            <form onSubmit={ Register }>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <div className="PopUp">
                    <div className="all Width_RecoverPassword">
                        <div className="div_data">                         
                            <div className="content">
                                <div className="div_title">
                                    <div className="title_popup">
                                        Recuperação de senha
                                    </div>
                                    <div className="">
                                        <a onClick={ ()=>{ ClosePopUp() } }>
                                            <SvgClose color="#F00000" className="icons" />
                                        </a>
                                    </div>
                                </div>
                                {
                                    msgReturn == "" ? 
                                    <>
                                        <div className="div_input">
                                            <div className="div_regi_data">
                                                <input type="email" className="input_email" required onChange={ (e)=>{ setEmail(e.target.value) } } />
                                            </div> 
                                            <span className="sub_name_input">Digite seu e-mail aqui</span>
                                        </div>
                                        <div className="button_popup">
                                            <button className="button_save_popup">
                                                Enviar
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <div className="">
                                        <div className="show_msg">
                                            { msgReturn }
                                        </div>
                                        <a href="http://localhost/3_crazy_pixel/dashboard_v3/public/php/generetor_pass.php?recover=recover/douguinhas12@gmail.com">Teste e-mail de Recuperação</a>
                                        <div className="button_popup return_resposta">
                                            <div className="button_save_popup" onClick={ ()=>{ ClosePopUp() } }>
                                                Fechar
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div> 
                    </div>
                </div>
            </form>
            : <React.Fragment></React.Fragment>
        )
    )
}