import { useState, useEffect } from "react";

import './Courses.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { SetListPag } from "interface/Page";
import { SvgListline, SvgListSquare, SvgSearch, SvgTriagle } from "components/SvgFile";

export default function Page_Courses(props){

    const [ dataPage, setDataPage ]         = useState(GetDataPage('courses'));
    const [ nameUser, setNameUser ]         = useState(GetUserData('user'));
    const [ listCategory, setListCategory ] = useState(ListCategory());
    const [ listCourses, setListCourses ]   = useState(GetDataPage('courses').list_courses);
    const [ typeList, setTypeList ]         = useState(false);
    const [ showCategory, setShowCategory ] = useState(false);
    const [ catSelected, setCatSelected ]   = useState('');

    const [ itensPage, setItensPage ] = useState(20);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(listCourses.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = listCourses.slice(startItens, endItens);
    
    useEffect(()=>{
        RegisterUserData('user', setNameUser); 
        RegisterDataPage('courses', setDataPage);
    }, []);

    function ListCategory(){
        const newData = [];
        GetDataPage('courses').list_courses.forEach(item =>{
            item.category.forEach(item_1 =>{
                newData.push(item_1.name);
            })
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }
    
    function ShowText(text){
        if(text.length > 65){
            return text.substring(0, 65) + "...";
        }
        return text;
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('courses').list_courses.forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });     
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListCourses(duplicate);
        }else {
            setListCourses(GetDataPage('courses').list_courses);
        }
        setCatSelected('Mostrar todas as categorias');
        setShowCategory(false);
    }

    function SearchCategory(value){
        if(value == 'Mostrar todas as categorias'){
            setCatSelected(value);
            setListCourses(GetDataPage('courses').list_courses);
        }else if(value){
            const newList = [];
            GetDataPage('courses').list_courses.forEach(item =>{
                item.category.forEach(item_1 =>{                    
                    if(item_1.name == value){
                        newList.push(item);
                    } 
                }); 
            });
            setCatSelected(value);
            setListCourses(newList);
        }else {
            setCatSelected(value);
            setListCourses(GetDataPage('courses').list_courses);
        }
        setShowCategory(false);
    }

    function OpenCourses(type, video, title){
        console.log(type, video, title);
        // SetModalData("ShowData", { "type": type, "video": video, "title": title });
        // SetModalState('ShowData', true);
    } 

    function CkickPage(value){        
        // SetListPag('page', value); 
    }

    return(
        <div className="Page_Courses">
            <div className="slideshow">
                {
                    Object.keys(nameUser).length != "" ? 
                    <>
                        <div className="list_icons highlight_icons">
                            <div className="show_names">
                                <div className="highlight_subtitle">
                                    Novo curso de
                                </div>
                                <div className="highlight_title">
                                    { dataPage.title }
                                </div>
                                <div className="highlight_text" dangerouslySetInnerHTML={ { __html: dataPage.text.replaceAll('&#34;', '"') } } />
                            </div>
                            <div className="button">
                                <div className="button_text" onClick={ ()=>{ CkickPage('register') } }>Assista agora</div>
                            </div>
                        </div>
                        <div className="show_img">
                            <img alt="img" src={ "./assets/" + dataPage.img } className="img_courses" />
                        </div>
                    </> :
                    <>
                        <div className="list_icons">
                            <div className="show_icons">
                                <div className="div_show_icon">
                                    <div className="">
                                        <img alt="img" src="./assets/icons/icon_3.png" className="show_icons_slide" />
                                    </div>
                                    <div className="width_icon">Assista os vídeos</div>
                                </div>
                                <div className="div_show_icon">
                                    <div className="">
                                        <img alt="img" src="./assets/icons/icon_2.png" className="show_icons_slide" />
                                    </div>
                                    <div className="width_icon">Execute os exercícios</div>
                                </div>
                                <div className="div_show_icon">
                                    <div className="">
                                        <img alt="img" src="./assets/icons/icon_1.png" className="show_icons_slide" />
                                    </div>
                                    <div className="width_icon">Tire dúvidas pelo Discord</div>
                                </div>
                            </div>
                            <div className="button">
                                <div className="button_text" onClick={ ()=>{ CkickPage('register') } }>Inscreva-se</div>
                            </div>
                        </div>
                        <div className="show_img">
                            <img alt="img" src="./assets/cursos.png" className="img_courses" />
                        </div>
                    </>
                }
            </div>
            <div className="container">
                <div className="show_search">
                    <div className="show_icons_list" onClick={ ()=>{ setTypeList(!typeList) } }>
                        {
                            typeList ? 
                            <SvgListline color="#193C4E" className="show_type_icon" /> :
                            <SvgListSquare color="#193C4E" className="show_type_icon" />
                        }
                    </div>
                    <div className="div_search">
                        <SvgSearch color="#ffffff" className="icons_search" />
                        <input type="text" className="input_search" onChange={ (e)=>{ SearchInput(e.target.value) } } />
                    </div>
                    <div className="div_select">
                        <div className="select_category" onClick={ ()=>{ setShowCategory(!showCategory) } }>
                            <div className="">
                                {
                                    catSelected !='' ? catSelected : "Mostrar todas as categorias"
                                }
                            </div>
                            <div className="show_type_triagle">
                                {
                                    showCategory ? 
                                    <SvgTriagle color="#FFFFFF" className="triagle triagle_active"/> :
                                    <SvgTriagle color="#FFFFFF" className="triagle"/>
                                }
                            </div>
                        </div>
                        {
                            showCategory ? 
                            <div className="list_category">
                                <div className="show_name_category" onClick={ ()=>{ SearchCategory("Mostrar todas as categorias"); } }>
                                    Mostrar todas as categorias
                                </div>
                                {
                                    Object.keys(listCategory).map((key, index)=>{
                                        return(
                                            <div className="show_name_category" key={ index } onClick={ ()=>{ SearchCategory(listCategory[key]); } }>
                                                { listCategory[key] }
                                            </div>
                                        )
                                    })
                                }
                            </div> : ""
                        }
                    </div>
                </div>
                <div className={ typeList ? "list_courses_line" 
                    : 
                    currentItens.length < 4 ? "list_courses_square alt_posit" : "list_courses_square" }>
                    {
                        currentItens.length >0 ?
                        currentItens.map((key, index)=>{
                            return(
                                <div className={ typeList ? "list_line" : "list_square" } key={ index } onClick={ ()=>{ OpenCourses(key.type_video, key.video, key.title) } }>
                                    <div className="courses_img">
                                        <img alt="img" src={ key.img } className="img_"/>
                                    </div>
                                    <div className="courses_title">
                                        { 
                                            key.title 
                                        }
                                        <div className={ typeList ? "courses_text" : "courses_text text_align" } dangerouslySetInnerHTML={ { __html: typeList ? key.text.replaceAll('&#34;', '"') : ShowText(key.text.replaceAll('&#34;', '"')) } } />
                                    </div>
                                </div>
                            )
                        }) 
                        : <div className="no_data">Nenhum curso encontrado...</div>
                    }
                </div>                
                {
                    listCourses.length >= 20 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page page_active" : "numb_page" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : 
                    <div className="space_not_data" />
                }
            </div>
        </div>
    )
}