import { useState, useEffect } from "react";

import './Login.css';

import Cookies from 'universal-cookie';

import { cookie_email, cookie_passw } from "dataFixed";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

import Page_Dashboard from "../Dashboard";

import { SvgLogotipo } from "components/SvgFile";

import { Access } from "services/AccessLogin";

import PopUp_RecoverPassword from "components/PopUp/RecoverPassword";

export default function Page_Login(props){

    const cookies = new Cookies();

    const [ loading, setLoading ]   = useState(false);
    const [ email, setEmail ]       = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    const [ accessChecked, setAccessChecked ] = useState(false);

    useEffect(()=>{        
        if(cookies.get(cookie_passw) || cookies.get(cookie_email)){
            setLoading(true);
            let return_pass  = cookies.get(cookie_passw);
            let return_email = cookies.get(cookie_email);
            Access('return', return_email, return_pass, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() })
        }
    }, []);

    function Register(event){
        event.preventDefault();
        Access('logar', email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        setAccessChecked(true);
    }

    function CallbackError(){
        setLoading(false);
        setAccessChecked(false);
        setMsgError('E-mail ou senha incorreta!');
    }

    function RecoverPassword(){
        SetModalState('RecoverPassword', true);
    }

    function CkickPage(value){        
        SetListPag('page', value); 
    }
    
    return(
        <>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            {
                accessChecked == true ? 
                <Page_Dashboard /> :
                <div className="Page_Login">
                    <form onSubmit={ Register } className="div_login">
                        <div className="logotipo">
                            <SvgLogotipo widthLogo="width_logo" className="svg_logotipo" />
                        </div>
                        <div className="show_login">

                            <div className="div_input">
                                <div className="">E-mail</div>
                                <div className="space_input">
                                    <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } placeholder="teste@teste.com" required />
                                </div>
                            </div>

                            <div className="div_input">
                                <div className="">Senha</div>
                                <div className="space_input">
                                    <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } placeholder="****" required />
                                </div>
                            </div>

                            <div className={ msgError ? "msg_error" : "msg_none" }>
                                { msgError }
                            </div>

                            <div className="div_button">
                                <button type="submit" className="button_login">
                                    Login
                                </button>
                            </div>

                            <div className="register">
                                <div className="text_1" onClick={ ()=>{ RecoverPassword() } }>Esqueci minha senha</div>
                                <div className="text_2" onClick={ ()=>{ CkickPage('register') } }>Inscreva-se</div>
                            </div>

                        </div>
                    </form>
                    <PopUp_RecoverPassword />
                </div>
            }
        </>
    )
}