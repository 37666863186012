import { useState, useEffect } from "react";

import './FixedTopic.css';

import { Link } from "react-router-dom";

import { SvgMenu } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

export default function FixedTopic(props){

    const [ namePage, setNamePage ]          = useState(GetListPag('currentPage'));
    const [ showMenuName , setShowMenuName ] = useState(GetDataPage('menuName'));

    const [ dataUser, setDataUser ] = useState(GetUserData('user'));
    const [ typeUser, setTypeUser ] = useState(GetUserData('access_type'));
    
    const [ status, setStatus ]     = useState(false);

    useEffect(()=>{
        RegisterListPag('currentPage', setNamePage);
        RegisterDataPage('menuName', setShowMenuName);

        RegisterUserData('user', setDataUser);
        RegisterUserData('access_type', setTypeUser);
    }, []);

    function AccessUser(){
        switch (dataUser.nivel) {
            case "0":
                return (
                    <span style={ { color:'#97C107' } }>{ typeUser }</span>
                );
            case "1":
                return (
                    <span style={ { color:'#A8662D' } }>{ typeUser }</span>
                );
            case "2":
                return (
                    <span style={ { color:'#D3D3D3' } }>{ typeUser }</span>
                );
            case "3":
                return (
                    <span style={ { color:'#FFB800' } }>{ typeUser }</span>
                );
        }
    }

    return(
        <header className="FixedTopic">
            <div className="Container ListMenu">
                <a href="/#index" onClick={ ()=>{ props.CkickPage('index'); setStatus(false); } }>
                    <div className="div_logo">
                        <img src="./assets/icons/logo.png" className="logotipo" />
                    </div>
                </a>
                <div className="mobile" onClick={ ()=>{ setStatus(!status); } }>
                    <SvgMenu color="#FFFFFF" className="icon_menu" />
                </div>
                <div className={ status == true ? "div_menu open_menu" : "div_menu" }>
                    {
                        Object.keys(showMenuName).map((key, index)=>{
                            if(showMenuName[key].page == 'blog'){
                                return(
                                    <a className="show_opt_menu" href="https://blog.crazypixel.com.br/" target="_blank" key={ index }>
                                        <div className="name_menu blog">{ showMenuName[key].name }</div>
                                    </a>
                                )
                            }else {
                                return(                                        
                                    <a className={ namePage == showMenuName[key].page ? "show_opt_menu menu_active" : "show_opt_menu" } href={ "/#" + showMenuName[key].page } key={ index } onClick={ ()=>{ props.CkickPage(showMenuName[key].page); setStatus(false); } }>
                                        <div className="name_menu">
                                            { showMenuName[key].name }
                                        </div>
                                    </a>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </header>
    )
}