// Connection type
// const pageServidor = 'http://localhost/React_JS/project_dfl/modelo_dash_1/site_v1/public';
const pageServidor = '.';
export const connectionPage = pageServidor;

// Cookies type
// const cookiesServidor = 'localhost';
const cookiesServidor = 'https://previa.ampla-midia.com//';
export const cookiesRegister = cookiesServidor;

// Cookies name
export const cookie_site    = 'project_dfl';
export const cookie_page    = 'project_dfl_page';
export const cookie_passw   = 'project_dfl_passw';
export const cookie_email   = 'project_dfl_email';
export const cookie_id      = 'project_dfl_id';
export const cookie_checked = 'cookie_register';