import { useState, useEffect } from "react";

import './Rolo.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import ModelPages_Home from "components/ModelPages/Home";
import ModelPages_VideoPresentation from "components/ModelPages/VideoPresentation";
import ModelPages_StudentWorks from "components/ModelPages/StudentWorks";
import ModelPages_ExplanationContents from "components/ModelPages/ExplanationContents";
import ModelPages_PersonalizedService from "components/ModelPages/PersonalizedService";
import ModelPages_TeacherProfile from "components/ModelPages/TeacherProfile";
import ModelPages_ModulesWorkload from "components/ModelPages/ModulesWorkload";
import ModelPages_Depositions from "components/ModelPages/Depositions";

export default function Page_Rolo(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('rolo'));

    useEffect(()=>{
        RegisterDataPage('rolo', setDataPage);
    }, []);

    function TypeDataPage(value, index){
        switch(value){
            case "Home":
                return <ModelPages_Home type="rolo" index={ index } />;

            case "Apresentação com vídeo":
                return <ModelPages_VideoPresentation type="rolo" index={ index } />;
            
            case "Trabalhos de alunos":
                return <ModelPages_StudentWorks type="rolo" index={ index } />;
            
            case "Explicação dos conteúdos":
                return <ModelPages_ExplanationContents type="rolo" index={ index } />;
            
            case "Atendimento personalizado":
                return <ModelPages_PersonalizedService type="rolo" index={ index } />;
            
            case "Perfil do professor":
                return <ModelPages_TeacherProfile type="rolo" index={ index } />;
            
            case "Módulos e carga horária":
                return <ModelPages_ModulesWorkload type="rolo" index={ index } />;

            case "Depoimentos":
                return <ModelPages_Depositions type="rolo" index={ index } />;

            // case "paymenttypes":
            //     return <PaymentTypes type="rolo" index={ index } />;
        }
    }
    
    return(
        <div className="Page_Rolo">
            {
                Object.keys(dataPage).map((key, index)=>{
                    return(
                        <div className="show_data_page" key={ index }>
                            {
                                TypeDataPage(dataPage[key].type, index)
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}