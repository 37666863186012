import { useState, useEffect, useRef } from "react";

import './TeacherProfile.css';

import { GetDataPageID, RegisterDataPage } from "interface/Data";

export default function ModelPages_TeacherProfile(props){

    const textHeight = useRef();

    const [ showData, setShowData ] = useState(GetDataPageID(props.type, props.index));
    const [ height, setHeight ]     = useState(0);
    const [ checked, setChecked ]   = useState(0);
    
    useEffect(()=>{
        RegisterDataPage(props.type, setShowData);
    }, []);
    
    useEffect(()=>{
        setInterval(() => {
            setChecked(1);
            if(textHeight?.current){
                setHeight(textHeight?.current.clientHeight + 60);
                window.onresize = function(){
                    setChecked(1);
                    setHeight(textHeight?.current.clientHeight + 60);
                };
            }
        }, 100);   
    }, [checked]); 

    function ShowDataProfile(){
        return(
            <div className="show_data">
                <div className="name_profile">                        
                    <div className="">
                        <img alt="" src={ showData.img } className="img_profile" />
                    </div>      
                    <div className="div_name">
                        <span className={ showData.type_align == "right" ? "type_2_name" : "type_1_name" }>{ showData.name_1 }</span>
                        <span className="subname">{ showData.name_2 }</span>
                    </div>
                </div>
                <div className="text" dangerouslySetInnerHTML={ { __html: showData.text_1.replaceAll('&#34;', '"') } } />
                <div className="portfolio text">             
                    <div className="">Portfólio</div>                    
                    <div className="">
                        <a href={ showData.behance } target="_blank">
                            <img alt="" src="./assets/icons/behance.png" className="icons_" />
                        </a>
                    </div>          
                    <div className="">
                        <a href={ showData.artstation } target="_blank">
                            <img alt="" src="./assets/icons/artstation.png" className="icons_" />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    function ShowDataFile(){
        return(
            <div className="file">    
                <img alt="" src={ showData.sample_image } className="img_bg" />
            </div>
        )
    }
    
    return(
        showData.type_bg == 'Imagem de fundo' ?
        <div className="ModelPages_TeacherProfile" style={ { height: height + 'px' } }>
            <div className="container show_profile" ref={ textHeight }>
                {
                    showData.type_alignment == "right" ? 
                    <>
                        { ShowDataFile(showData.type_align) }
                        { ShowDataProfile(showData.type_align) }
                    </> :   
                    <>
                        { ShowDataProfile(showData.type_align) }
                        { ShowDataFile(showData.type_align) }
                    </>   
                }
            </div>
            <div className="background_image">
                <img alt="img" src={ showData.bg_page } className="img_home" />
            </div>
        </div> :
        <div className="ModelPages_TeacherProfile" style={ { background: showData.bg_page } }>
            <div className="container show_profile" ref={ textHeight }>
                {
                    showData.type_alignment == "right" ? 
                    <>
                        { ShowDataFile(showData.type_align) }
                        { ShowDataProfile(showData.type_align) }
                    </> :   
                    <>
                        { ShowDataProfile(showData.type_align) }
                        { ShowDataFile(showData.type_align) }
                    </>   
                }
            </div>
        </div>
    )
}