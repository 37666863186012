// to the website
let DataPage = {
    "menuName"         : {},
    "home"             : {},
    "howworks"         : {},
    "mentoring"        : {},
    "courses"          : {},
    "rolo"             : {},
    "paymenttypes"     : {}
};

let NotifyDataPage = {
    "menuName"         : [],
    "home"             : [],
    "howworks"         : [],
    "mentoring"        : [],
    "courses"          : [],
    "rolo"             : [],
    "paymenttypes"     : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["menuName"].forEach(element => {
        element(value);
    });
    NotifyDataPage["home"].forEach(element => {
        element(value);
    });
    NotifyDataPage["howworks"].forEach(element => {
        element(value);
    });
    NotifyDataPage["mentoring"].forEach(element => {
        element(value);
    });
    NotifyDataPage["courses"].forEach(element => {
        element(value);
    });
    NotifyDataPage["rolo"].forEach(element => {
        element(value);
    });
    NotifyDataPage["paymenttypes"].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function GetDataPageID(key, index){
    return DataPage[key][index];
}

export function RegisterDataPage(key, value){
    NotifyDataPage[key].push(value);
}