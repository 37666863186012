import Axios from 'axios';

import Cookies from 'universal-cookie';

import { connectionPage, cookiesRegister, cookie_email, cookie_id, cookie_page, cookie_passw, cookie_site } from 'dataFixed';

import { SetListPag }  from 'interface/Page.js';
import { SetUserData } from 'interface/Users.js';

export function Access(type, reg_email, reg_pass, CallbackSuccess, CallbackError){ 
    const cookies = new Cookies(); 

    const data    = new FormData();
    data.append('logar', 'logar');
    data.append('type', type);
    data.append('email', reg_email);
    data.append('password', reg_pass);
    
    Axios({
        url     : connectionPage + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        console.log(response.data);
        switch (response.data.access_type) {
            case "erro":
                    CallbackError();
                    cookies.set(cookie_site, 'login', '/', cookiesRegister);

                    cookies.remove(cookie_passw, "", '/', cookiesRegister);
                    cookies.remove(cookie_email, "", '/', cookiesRegister);
                break;

            default:       
            
                    if(cookies.get(cookie_site)){ 
                        let cookiesPages_ = cookies.get(cookie_site); 
                        SetListPag('page', cookiesPages_); 
                    }else {
                        cookies.set(cookie_site, 'login', '/', cookiesRegister);
                        SetListPag('page', 'login');
                    }

                    cookies.set(cookie_passw, response.data.user.pass, '/', cookiesRegister);
                    cookies.set(cookie_email, response.data.user.email, '/', cookiesRegister);

                    SetUserData('user', response.data.user);     
                    SetUserData('access_type', response.data.access_type); 
                    CallbackSuccess();         
                break;
        }
    }).catch((error)=>{
        CallbackError();
        cookies.set(cookie_site, 'login', '/', cookiesRegister);
        
        cookies.remove(cookie_passw, "", '/', cookiesRegister);
        cookies.remove(cookie_email, "", '/', cookiesRegister);
    })
}

export function Reg_AltPhoto(file, CallbackSuccess, CallbackError){ 

    const cookies    = new Cookies();    
    let return_pass  = cookies.get(cookie_passw);
    let return_email = cookies.get(cookie_email);

    const data = new FormData();
    data.append('logar', 'alt_photo');

    data.append('file', file);
    data.append('email', return_email);
    data.append('password', return_pass);

    Axios({
        url     : connectionPage + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        console.log(response.data);
        switch (response.data.access_type) {
            case "erro":
                break;
            
            default:
                    SetUserData('user', response.data.user);     
                    SetUserData('access_type', response.data.access_type); 
                    CallbackSuccess(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_AltData(nameUser, loginUser, phoneUser, emailUser, passUser, CallbackSuccess, CallbackError){ 

    const cookies    = new Cookies();    
    let return_pass  = cookies.get(cookie_passw);
    let return_email = cookies.get(cookie_email);

    const data = new FormData();
    data.append('logar', 'alt_data');

    data.append('nameUser', nameUser);
    data.append('loginUser', loginUser);
    data.append('phoneUser', phoneUser);
    data.append('emailUser', emailUser);
    data.append('passUser', passUser);

    data.append('email', return_email);
    data.append('password', return_pass);
    Axios({
        url     : connectionPage + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data.access_type) {
            case "erro":
                break;
            
            default:
                    cookies.set(cookie_passw, response.data.user.pass, '/', cookiesRegister);
                    cookies.set(cookie_email, response.data.user.email, '/', cookiesRegister);

                    SetUserData('user', response.data.user);     
                    SetUserData('access_type', response.data.access_type); 
                    CallbackSuccess(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}