import { useState, useEffect } from "react";

import './FixedFooter.css';

export default function FixedFooter(props){
    
    return(
        <footer className="FixedFooter">
            <div className="div_top">      
                <div className="container div_end_flex">
                    <div className="">
                        <div className="">Redes Sociais</div>   
                        <div className="list_social_network">   
                            <a href="https://www.facebook.com/CrazyPixelSchool" target="_blank">                    
                                <div className="facebook">
                                    <img alt="facebook" src="./assets/icons/facebook.png" className="icons_footer" />
                                </div> 
                            </a>                    
                            <a href="https://twitter.com/CrazyPixelBR/" target="_blank"> 
                                <div className="twitter">
                                    <img alt="twitter" src="./assets/icons/twitter.png" className="icons_footer" />
                                </div>       
                            </a>
                            <a href="https://www.youtube.com/channel/UCrEzrm1SzAjI9Z0bVxPqMjA" target="_blank">              
                                <div className="youtube">
                                    <img alt="youtube" src="./assets/icons/youtube.png" className="icons_footer" />                            
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="div_menu_site">
                        <div className="space_footer">
                            <div className="name_menu">Perguntas frequentes</div>
                            <div className="name_menu">Política de privacidade</div>
                            <div className="name_menu">Professores</div>
                        </div>   
                        <div className="space_footer">
                            <a href="/#index" className="page_site" id="page_site" onClick={ ()=>{ props.CkickPage('index'); props.animateScroll() } }>
                                <div className="name_menu">Home</div>
                            </a>
                            <a href="/#howworks" className="page_site" onClick={ ()=>{ props.CkickPage('howworks'); } }>
                                <div className="name_menu">Como funciona</div>
                            </a>
                            <a href="/#courses" className="page_site" onClick={ ()=>{ props.CkickPage('courses'); } }>
                                <div className="name_menu">Cursos</div>
                            </a>
                        </div> 
                        <div className="space_footer">
                            <a href="/#mentoring" className="page_site" onClick={ ()=>{ props.CkickPage('mentoring'); } }>
                                <div className="name_menu">Mentoria</div>
                            </a>
                            <a href="/#rolo" className="page_site" onClick={ ()=>{ props.CkickPage('rolo'); } }>
                                <div className="name_menu">Rolo</div>
                            </a>
                            <a href="/#blog" className="page_site" onClick={ ()=>{ props.CkickPage('blog'); } }>
                                <div className="name_menu">Blog</div>
                            </a>
                        </div> 
                    </div>
                </div>
            </div>
            <div className="div_middle">                
                <div className="container div_end_flex">
                    <div className="">
                        <div className="">Seus dados e compra estão 100% seguros</div>
                        <div className="list_social_network">                        
                            <div className="">
                                <img alt="" src="./assets/icons/img_1.png" className="img_footer" />
                            </div>
                            <div className="">
                                <img alt="" src="./assets/icons/img_2.png" className="img_footer" />
                            </div>   
                        </div>
                    </div>
                    <div className="">
                        <div className="div_middle_title_right">Aceitamos as principais formas de pagamento</div>                        
                        <div className="list_social_network">                        
                            <div className="">
                                <img alt="" src="./assets/icons/img_3.png" className="img_footer" />
                            </div>     
                        </div>                                                
                    </div>
                </div>
            </div>
            <div className="div_end">
                <div className="container div_end_flex">
                    <div className="">
                        Rua Jovino do Rosário, 1674 - Boa Vista - Curitiba - PR - 82560-435
                    </div>
                    <div className="">
                        © 2017 - 2023 Crazy Pixel School LTDA - CNPJ: 45.033.422/0001-00
                    </div>
                </div>
            </div>
        </footer>
    )
}