import { useState, useEffect } from "react";

import './Enrollment.css';

export default function Page_Enrollment(){
    return(
        <div className="Page_Enrollment">
            <a mp-mode="dftl"
                href="https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c93808486e98e450186eb8d882b009e"
                name="MP-payButton" className='blue-ar-l-rn-none'>
                Suscribirme
            </a>
        </div>
    )
}